import React, { useState, useEffect, useRef } from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../../layout/Layout';
import SEO from '../../components/Seo';
import Nav from '../../components/Nav/Nav';
import { Helmet } from 'react-helmet';



const Privacy = ({ data }) => {
  const [content, setContent] = useState("")
  const policyTextRef = useRef()
  const link = "https://privacyportal-cdn.onetrust.com/73dca12b-5ba4-4937-9072-b5ffa15d1ba7/privacy-notices/c4bf13c9-3e34-4792-8ca5-3debf35b55da-fr.json"

  useEffect(() => {
    fetch(link)
      .then((response) => response.json())
      .then((data) => setContent(data.notices[Object.keys(data.notices)[0]]));
  }, [])

  return (
    <Layout>
      <SEO
        lang="fr"
        title='Publicis et Nous'
        description='Publicis et Nous'
      />
      <div id="main-wrapper" className="row">
        <Helmet>
          <script src="https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/css/v2/otnotice-core.css" integrity="sha384-DztdAPBWPRXSA/3eYEEUWrWCy7G5KFbe8fFjk5JAIxUYHKkDx6Qin1DkWx51bBrb" crossorigin="anonymous"></script>
          <link rel="stylesheet" type="text/css" href="https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/css/v2/otnotice-core.css" class="otnotice-css"></link>
        </Helmet>
        <Nav />
        <section id="container" className="privacy col-lg-9 col-md-12">
          <div className="page-data-privacy inner-wrap">
            <div className="page-content inner-page">
              <div className="content-wrap">
                <div id="otnotice-cf03b220-3512-4bd1-9453-07b7a003b08e" className="otnotice mt-5">
                  <div className="otnotice-content">
                    <ul className="otnotice-menu">
                      <li className="otnotice-menu-section"><a
                              href="#otnotice-section-d51091f2-d876-4a64-acd5-9e8f935d1e8f">Introduction</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-cc500d40-b884-4805-ab0a-ad61207e7aa0">Privacy
                              Policy</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-06c367d6-c4ae-44f1-b767-1b1f9d68dab9">I.
                              Purposes of the processing of Personal Data</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-d22ca56f-26b3-442a-af5d-6ffe6961c4e1">II. What
                              Personal Data do we collect and process?</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-94b70614-39ef-4b12-920a-d84859a083fa">III. What
                              legal basis do we use?</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-e166a121-bb93-4fc0-ae9e-daa80793e068">IV. With
                              whom do we share your Personal Data?</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-8bd9a325-5a4b-4978-98bb-fd69691e0765">V. Your
                              rights regarding your Personal Data</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-2f043d2d-cd50-4e1f-b0f0-988cf73af457">VI.
                              Retention periods</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-069be978-f524-4a38-b76b-d293c335b08a">VII. Data
                              security</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-0c05eb29-73cf-49b4-8bc7-61b07cbe286b">VIII.
                              Data transfer</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-c1f113a9-f24d-485f-8c11-5a9d0bdae412">IX.
                              Additional information</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-0185cda7-df3c-4f59-8679-5b44dd2c4467">Cookie
                              Notice</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-b6d2a51f-25fe-48c2-a946-668f8ff5a403">I. Social
                              networks</a></li>
                      <li className="otnotice-menu-section"><a href="#otnotice-section-ed37efa9-a714-43cf-9c62-27750645d616">II.
                              Cookies</a></li>
                    </ul>
                    <div className="otnotice-menu-mobile">
                      <div className="otnotice-menu-selected-container"><span className="otnotice-menu-selected"
                              id="otnotice-menu-selected">Introduction</span><span
                              className="otnotice-menu-display otnotice-menu-display__expand"
                              id="otnotice-collapse-expand-icon"></span></div>
                      <ul className="otnotice-menu-mobile-container" style={{display: "none"}}>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-d51091f2-d876-4a64-acd5-9e8f935d1e8f">Introduction</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-cc500d40-b884-4805-ab0a-ad61207e7aa0">Privacy Policy</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-06c367d6-c4ae-44f1-b767-1b1f9d68dab9">I. Purposes of the processing of
                                  Personal Data</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-d22ca56f-26b3-442a-af5d-6ffe6961c4e1">II. What Personal Data do we
                                  collect and process?</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-94b70614-39ef-4b12-920a-d84859a083fa">III. What legal basis do we
                                  use?</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-e166a121-bb93-4fc0-ae9e-daa80793e068">IV. With whom do we share your
                                  Personal Data?</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-8bd9a325-5a4b-4978-98bb-fd69691e0765">V. Your rights regarding your
                                  Personal Data</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-2f043d2d-cd50-4e1f-b0f0-988cf73af457">VI. Retention periods</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-069be978-f524-4a38-b76b-d293c335b08a">VII. Data security</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-0c05eb29-73cf-49b4-8bc7-61b07cbe286b">VIII. Data transfer</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-c1f113a9-f24d-485f-8c11-5a9d0bdae412">IX. Additional information</a>
                          </li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-0185cda7-df3c-4f59-8679-5b44dd2c4467">Cookie Notice</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-b6d2a51f-25fe-48c2-a946-668f8ff5a403">I. Social networks</a></li>
                          <li className="otnotice-menu-section-mobile"><a
                                  href="#otnotice-section-ed37efa9-a714-43cf-9c62-27750645d616">II. Cookies</a></li>
                      </ul>
                    </div>
                    <div className="otnotice-sections">
                      <section className="otnotice-section" id="otnotice-section-d51091f2-d876-4a64-acd5-9e8f935d1e8f">
                          <h2 className="otnotice-section-header">Introduction</h2>
                          <div className="otnotice-section-content">
                              <p id="isPasted">This Privacy Policy ("<strong>Policy</strong>") and the Cookie Notice
                                  ("<strong>Notice</strong>") are intended to provide the user (“<strong>You</strong>” or the
                                  “<strong>User</strong>”) with information about how Publicis EtNous, operated by Services
                                  Marketing Diversifiés, uses and protects your personal data ("<strong>Personal Data</strong>")
                                  collected through the <a href="https://publicisetnous.fr"
                                      rel="nofollow">https://publicisetnous.fr</a> website ("<strong>Site</strong>").</p>
                              <p>If You have any questions about how your Personal Data is collected, please contact the Data
                                  Protection Officer of Services Marketing Diversifiés at <a
                                      href="mailto:privacyofficer@publicisgroupe.com"
                                      rel="nofollow">privacyofficer@publicisgroupe.com</a>.</p>
                              <p>Personal Data collected by the Site will only be used for the purposes specified in the Policy
                                  and in the Notice.&nbsp;Once collected, Personal Data will not be used for any other
                                  incompatible purpose without your consent.</p>
                              <p>Services Marketing Diversifiés, 133, avenue des Champs Elysées, 75008 – Paris, registered in the
                                  Paris Trade and Companies under the number 337 934 483 is considered as the Data Controller of
                                  this Site.</p>
                              <p>We take the protection of Personal Data very seriously. All Personal Data collected by the Site
                                  will be treated as strictly confidential and will only be used in the manner described in this
                                  Policy and in accordance with applicable laws.</p>
                              <p>Therefore, please read this Policy carefully. If You do not agree with the way your Personal Data
                                  will be used, please do not use this Site or provide your Personal Data through this Site.</p>
                              <p>We may include links on the Site to other websites, including social networking platforms. This
                                  Policy only addresses how we use Personal Data and does not cover those other websites. We
                                  encourage You to read the privacy statements available on the other websites You visit.&nbsp;
                              </p>
                              <p>The Policy and the Notice may be modified by Services Marketing Diversifiés as the Site's
                                  features and services evolve and as laws applicable to Services Marketing Diversifiés'
                                  activities change. These modifications and updates are binding on the User who must therefore
                                  refer to this section regularly and check the documents in force.</p>
                              <p>If You wish to save this text, please select the entire declaration with your mouse and copy and
                                  paste by pressing the ctrl-c key.</p>
                              <p><strong>Date of last update: September 13, 2022</strong></p>
                              <p style={{textAlign: "left"}}><br></br></p>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-cc500d40-b884-4805-ab0a-ad61207e7aa0">
                          <div className="otnotice-section-content">
                              <p><strong id="isPasted"><span style={{fontSize: 24+"px"}}>Privacy Policy</span></strong></p>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-06c367d6-c4ae-44f1-b767-1b1f9d68dab9">
                          <h2 className="otnotice-section-header">I. Purposes of the processing of Personal Data</h2>
                          <div className="otnotice-section-content">
                              <p id="isPasted">The purposes of the collection of Personal Data by the Site are as follows:</p>
                              <ul>
                                  <li>Respond quickly and efficiently to your requests sent to us via the contact email addresses
                                      indicated on our Site;</li>
                                  <li>Respond to your requests on social networks, and get to know You better;</li>
                                  <li>To maintain and preserve the security of the Site, however, please note that we use
                                      anonymous Statistical Data to the extent possible;</li>
                                  <li>To enable your access to the Site and its features;</li>
                                  <li>To obtain statistics on the use of the Site in order to improve its functioning and its
                                      services;</li>
                                  <li>Subject to your subscription to our newsletter, to send communications relating to the
                                      Agency's news;</li>
                                  <li>To process and respond to your request concerning the exercise of your rights listed in
                                      Article V hereof.</li>
                              </ul>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-d22ca56f-26b3-442a-af5d-6ffe6961c4e1">
                          <h2 className="otnotice-section-header">II. What Personal Data do we collect and process?</h2>
                          <div className="otnotice-section-content">
                              <p id="isPasted">By Personal Data, we mean any information relating to an identified or identifiable
                                  natural person (i.e. any living person).</p>
                              <p><br></br></p>
                              <p>We collect the following Personal Data through the Site:</p>
                              <ol>
                                  <li>Your personal information: we collect certain pieces of personal information such as your
                                      first name, surname, and e-mail address.</li>
                                  <li>The content of the messages and/or requests that You send to us</li>
                              </ol>
                              <p><br></br></p>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-94b70614-39ef-4b12-920a-d84859a083fa">
                          <h2 className="otnotice-section-header">III. What legal basis do we use?</h2>
                          <div className="otnotice-section-content">
                              <p id="isPasted">The processing of Personal Data collected on the Site is based on:</p>
                              <ul>
                                  <li>Respond to your requests sent to us via the email addresses indicated on our Site;</li>
                                  <li>The execution of pre-contractual measures, when we process your messages sent by email when
                                      this request has a commercial scope (requests relating to our services or our offers);</li>
                                  <li>Our legitimate interests, when we process your data in the context of the maintenance and
                                      implementation of measures to preserve the security of the Site;</li>
                                  <li>Our legitimate interests, when we (i) process data collected via social networks in order to
                                      better know our subscribers and understand the public reaction to our products and services,
                                      (ii) conduct social listening to identify and evaluate what is being said publicly about us
                                      on social networks in order to understand industry trends and market sentiment;</li>
                                  <li>The execution of contractual measures, when we deposit functional and/or technical cookies;
                                  </li>
                                  <li>Your consent, for the use of certain cookies (see our Cookies Policy below for details);
                                  </li>
                                  <li>Your consent, for the sending of our newsletter;</li>
                                  <li>Our legal obligation, when we process your requests related to the exercise of your rights
                                      listed in Article V hereof.</li>
                              </ul>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-e166a121-bb93-4fc0-ae9e-daa80793e068">
                          <h2 className="otnotice-section-header">IV. With whom do we share your Personal Data?</h2>
                          <div className="otnotice-section-content">
                              <p id="isPasted">When it is justified, your Personal Data may be shared within the organisations of
                                  the Publicis Group and its subsidiaries in France.</p>
                              <p>In the event that You wish to exercise your rights as listed in Article V hereof, your request
                                  will be collected by means of the form provided by our partner OneTrust, which acts on our
                                  instructions and on our behalf as a sub-contractor as defined in European Regulation 2016/679 on
                                  the protection of personal data (“GDPR”).</p>
                              <p>Your Personal Data will not be passed on to third parties, unless otherwise specified in this
                                  Policy. Your Personal Data may be passed on:</p>
                              <ul>
                                  <li>in the context of a merger, acquisition, or sale of all or part of the assets of Services
                                      Marketing Diversifiés, to the successors in title to our business, or</li>
                                  <li>to establish, exercise, or defend our rights, or</li>
                                  <li>when required by law, to respond to requests from legal or administrative bodies, government
                                      authorities, or courts.</li>
                              </ul>
                              <p>If the services of third parties are required for the technical maintenance of the Site or the
                                  software processing your Personal Data, or for the hosting, backup, administer, or process or
                                  manage the Data and/or our e-mails, your cookie preferences, these third parties will have
                                  access to Personal Data only to the extent necessary to ensure the smooth and/or secure
                                  technical operation of the Site and our software. In any case, such access to your Personal Data
                                  will only be provided in accordance with applicable law, including with respect to Data
                                  confidentiality, privacy, and security obligations.</p>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-8bd9a325-5a4b-4978-98bb-fd69691e0765">
                          <h2 className="otnotice-section-header">V. Your rights regarding your Personal Data</h2>
                          <div className="otnotice-section-content">
                              <p id="isPasted">In accordance with the applicable regulations, You may exercise the following
                                  rights with respect to those of your Personal Data that we hold:</p>
                              <ul>
                                  <li>Right to information: You have the right to obtain clear, transparent, understandable, and
                                      easily accessible information about how we use your Personal Data, and about your rights.
                                      That is why we are providing You with such information in this Policy;</li>
                                  <li>Right of access to your Personal Data: You can access all the Personal Data that we hold
                                      about You, learn of the origin of this Personal Data, and obtain a copy of them in an
                                      understandable format. You may also exercise your right of access to your Personal Data in
                                      order to check the accuracy of the data and have them corrected or deleted. You do not have
                                      to indicate a reason to exercise your right of access;</li>
                                  <li>Right to rectify your Personal Data: in order to prevent inaccurate or incomplete Personal
                                      Data regarding You from being processed or disseminated, You can ask us to rectify them;
                                  </li>
                                  <li>Right to have your Personal Data deleted: You may ask for the erasure or deletion of the
                                      Personal Data held by us. This is not an absolute right, as we may be required to retain
                                      your Personal Data for legal or legitimate reasons. For example, You can exercise your right
                                      to deletion in the following cases:<ul>
                                          <li>if You have withdrawn your consent to processing (see below);</li>
                                          <li>if You have validly objected to the processing of your data (see below);</li>
                                          <li>when the data are not or are no longer necessary for the purposes for which they
                                              were originally collected or processed;</li>
                                          <li>the data have been processed unlawfully (e.g. publication of hacked data).</li>
                                      </ul>
                                  </li>
                                  <li>Right to object to the processing of your Personal Data: when we process your Personal Data
                                      on the basis of our legitimate interests (to find out what kinds of processing this may
                                      involve, please refer to Section III of this Policy), You may at any time object to the
                                      processing of your Personal Data on grounds relating to your particular circumstances.
                                      However, we may turn down such a request depending on the case if there are compelling
                                      legitimate grounds for processing such data which override your interests, rights, and
                                      freedoms, or if such data are necessary for the establishment, exercise, or defense of legal
                                      claims. You may unsubscribe from or object, at any time and without indicating any reason,
                                      to direct marketing messages. All You have to do is either (i) click on the link in the
                                      footer of the message You have received from us; or (ii) use the hyperlink below.</li>
                                  <li>Right to object to commercial prospecting: You may unsubscribe or object, at any time and
                                      without reason, to receiving commercial prospecting messages. Simply either (i) click on the
                                      link in the footer of the communications You receive from us; or (ii) use the hyperlink
                                      below.</li>
                                  <li>Right to restrict the processing of your Personal Data: the right to restrict processing
                                      complements the other rights that You have. This right means that the processing of your
                                      data by us is restricted, so that we may retain the data, but we may not use or process them
                                      in any other way. This right applies in specific circumstances, namely:<ul>
                                          <li>if You dispute the accuracy of your Personal Data. The processing is then restricted
                                              for some time, allowing us to verify the accuracy of the Personal Data;</li>
                                          <li>in cases where the processing is unlawful and You object to the erasure of your
                                              Personal Data, and instead ask for their use to be restricted;</li>
                                          <li>in cases where we no longer need the Personal Data for the purposes mentioned above
                                              in Section I, but where they are still necessary for You to establish, exercise, or
                                              defend legal claims;</li>
                                          <li>and in cases where You have objected to processing based on the legitimate
                                              interests: You may request that processing be restricted for the time necessary to
                                              determine whether we can comply with your objection (i.e. the period necessary to
                                              verify whether or not the legitimate interests of Services Marketing Diversifiés
                                              prevail over yours).</li>
                                      </ul>
                                  </li>
                                  <li>Right to the portability of your Personal Data: You may submit a request to obtain the
                                      Personal Data that You have provided to us, in a structured, commonly used, and
                                      machine-readable format, for your own use or to send them to a third party of your choice.
                                      This right applies only to the Personal Data that You have provided to us directly or
                                      indirectly and that have been processed using automated processes, where the processing is
                                      based on your consent or the performance of a contract. We invite You to peruse the list of
                                      the legal bases for our processing of Personal Data (in section III of this Policy) to find
                                      out whether our processing is based on the performance of a contract or on consent;</li>
                                  <li>Right to withdraw your consent to the processing of Data based on such consent at any time:
                                      You may consult section III of this Policy to identify the purposes for which the processing
                                      of your Personal Data is based on your consent.</li>
                                  <li>The right to give instructions as to what is to be done with your Data after your death: You
                                      can define guidelines regarding the retention, erasure, and disclosure of your Personal Data
                                      after your death. These directives can be general, i.e. concerning all the Personal Data
                                      about You, or specific, i.e. concerning only certain kinds of processing.</li>
                              </ul>
                              <p>Please click <a
                                      href="https://publicisresources-privacy.my.onetrust.com/webform/a57c3297-68d3-4bc0-8843-262957a92400/721918d4-6abc-4431-930c-ee52e10ca0c3"
                                      rel="nofollow">this link</a> and follow the instructions if You wish to exercise any of the
                                  above-mentioned rights. If we have reasonable doubts about your identity, we may ask You to
                                  attach any document that proves your identity, in order to prevent identity theft. Insofar as
                                  your request is received electronically, we will respond electronically unless You specify, in
                                  the comments area of the form to exercise your rights, that You wish to receive a response by
                                  another means, for example by post.</p>
                              <p>We will reply as soon as possible, and in any case within one month of receiving your request.
                                  This period may be extended by two months in order to handle the complexity of the request or
                                  the number of requests received. In such a situation, we will inform You of this no later than
                                  one month after receiving your request, and will specify the reasons for the extension of the
                                  response time. If You feel that we are not complying with this Policy, if You are not satisfied
                                  with the way we have handled your request, or if your request has been turned down, You may also
                                  make a formal complaint to the competent authority for the protection of Personal Data (in
                                  France, this is the Commission Nationale de l'Informatique et des Libertés (CNIL):&nbsp;<a
                                      href="https://www.cnil.fr/" rel="nofollow">https://www.cnil.fr</a>). However, we invite You
                                  to contact our Data Protection Officer at the following address:&nbsp;<a
                                      href="mailto:privacyofficer@publicisgroupe.com"
                                      rel="nofollow">privacyofficer@publicisgroupe.com</a> before filing a complaint with the
                                  competent Data Protection Authority.</p>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-2f043d2d-cd50-4e1f-b0f0-988cf73af457">
                          <h2 className="otnotice-section-header">VI. Retention periods</h2>
                          <div className="otnotice-section-content">
                              <p id="isPasted">We retain your Personal Data in accordance with the applicable legal data retention
                                  requirements and our internal policies. The retention of data will vary according to the type of
                                  data and the purposes for processing them.</p>
                              <p>Your Data are stored for the entire duration of our contractual relationship. After this period,
                                  your Data will be archived for the applicable duration which, in France, is 5 years after the
                                  end of our relationship and/or the last time that Services Marketing Diversifiés had contact
                                  with You.</p>
                              <p>Personal Data collected for commercial prospecting purposes are kept for a period of three (3)
                                  years from the last contact. As for the Personal Data collected in the context of your
                                  application for a position, they are stored:</p>
                              <ul>
                                  <li>for a period not exceeding 2 (two) years after the last time Services Marketing Diversifiés
                                      had contact with You if You are unsuccessful in your application, unless You indicate that
                                      You do not want us to retain your application after the recruitment process, in which case
                                      your Personal Data will be deleted immediately;</li>
                                  <li>for the duration of your presence at Services Marketing Diversifiés, if your application is
                                      accepted.</li>
                              </ul>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-069be978-f524-4a38-b76b-d293c335b08a">
                          <h2 className="otnotice-section-header">VII. Data security</h2>
                          <div className="otnotice-section-content">
                              <p style={{textAlign: "justify"}}>We use a variety of methods, such as firewalls, intrusion detection
                                  software, and manual security procedures, to protect your data against loss or damage, to help
                                  protect the accuracy and security of Personal Data, and to prevent unauthorised access or
                                  misuse. If &nbsp;You believe that the Site or any piece of Personal Data is not secure or that
                                  there has been unauthorised access to the Site or your Personal Data, please contact&nbsp;<a
                                      href="mailto:privacyofficer@publicisgroupe.com" id="isPasted"
                                      rel="nofollow">privacyofficer@publicisgroupe.com</a> immediately.</p>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-0c05eb29-73cf-49b4-8bc7-61b07cbe286b">
                          <h2 className="otnotice-section-header">VIII. Data transfer</h2>
                          <div className="otnotice-section-content">
                              <p id="isPasted">Due to the international nature of our business, your Personal Data are transferred
                                  outside the European Union. The Personal Data collected about You are transferred to the United
                                  States. As this country is not considered by the European Commission to offer an adequate level
                                  of protection, we ensure that all data transfers comply with the applicable legal requirements
                                  by signing, in accordance with Article 46 of the GDPR, standard data protection clauses adopted
                                  by the European Commission, with our co-contractors.</p>
                              <p>If You would like to know more about how the Data are protected, or if You would like to request
                                  a copy of the relevant safeguards, please send an e-mail to&nbsp;<a
                                      href="mailto:privacyofficer@publicisgroupe.com"
                                      rel="nofollow">privacyofficer@publicisgroupe.com</a>.</p>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-c1f113a9-f24d-485f-8c11-5a9d0bdae412">
                          <h2 className="otnotice-section-header">IX. Additional information</h2>
                          <div className="otnotice-section-content">
                              <p style={{textAlign: "left"}}>For more information on Publicis EtNous, please visit our website – <a
                                      href="https://publicisetnous.fr" id="isPasted" rel="nofollow">https://publicisetnous.fr</a>.
                                  If You feel that we are not abiding by this policy or if You have any questions about it, please
                                  contact&nbsp;<a href="mailto:privacyofficer@publicisgroupe.com"
                                      rel="nofollow">privacyofficer@publicisgroupe.com</a></p>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-0185cda7-df3c-4f59-8679-5b44dd2c4467">
                          <h2 className="otnotice-section-header">Cookie Notice</h2>
                          <div className="otnotice-section-content">
                              <p style={{textAlign: "justify"}}>The purpose of this Notice is to provide You with information
                                  regarding the storage and use of cookies, and other tracking technologies, as well as the means
                                  made available to You to object to them.</p>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-b6d2a51f-25fe-48c2-a946-668f8ff5a403">
                          <h2 className="otnotice-section-header">I. Social networks</h2>
                          <div className="otnotice-section-content">
                              <p id="isPasted">If You follow us on social media (through our LinkedIn, Instagram, and YouTube
                                  accounts), we will also collect personal data about You in order to get to know our subscribers
                                  better and to understand the public’s reactions to our products and services. We may use this
                                  information to contact You.</p>
                              <p>The use of Personal Data mentioned above is only carried out in accordance with the applicable
                                  laws (for example, if You request us to contact You or if your specific express consent is
                                  required). We have no control over how social networking platforms use your Personal Data.</p>
                              <p>We encourage you to read the privacy statements of the various social networking platforms that
                                  You use. To learn more about how these social networking platforms use your Personal Data:</p>
                              <ul>
                                  <li>YouTube (Google's general privacy policy) - <a
                                          href="https://policies.google.com/privacy?hl=en"
                                          rel="nofollow">https://policies.google.com/privacy?hl=en</a></li>
                                  <li>Instagram - <a href="https://help.instagram.com/519522125107875"
                                          rel="nofollow">https://help.instagram.com/519522125107875</a></li>
                                  <li>LinkedIn - <a
                                          href="https://www.linkedin.com/legal/privacy-policy?trk=homepage-basic_footer-privacy-policy"
                                          rel="nofollow">https://www.linkedin.com/legal/privacy-policy?trk=homepage-basic_footer-privacy-policy</a>
                                  </li>
                              </ul>
                              <p>If You have any questions about the way in which your Personal Data are collected, we request You
                                  to contact Services Marketing Diversifiés' Data Protection Officer at the following address: <a
                                      href="mailto:privacyofficer@publicisgroupe.com"
                                      rel="nofollow">privacyofficer@publicisgroupe.com</a>.</p>
                          </div>
                      </section>
                      <section className="otnotice-section" id="otnotice-section-ed37efa9-a714-43cf-9c62-27750645d616">
                          <h2 className="otnotice-section-header">II. Cookies</h2>
                          <div className="otnotice-section-content">
                              <p><strong>1. What are cookies?</strong></p>
                              <p><br></br></p>
                              <p>A "Cookie" or "tracker" is a small text file that is saved in the memory of your browser on your
                                  hard disk when You visit a website, read an e-mail, and install or use software or a mobile
                                  application, and that makes it possible to record and track data concerning your use of the
                                  website. These data do not identify You directly, but they may provide information about your
                                  preferences or your device. They are mainly used to ensure the proper functioning of the Site,
                                  as well as to enable us to obstain statistics and details about Site visits. These data do not
                                  generally identify You directly, but they can provide You with a personalised web experience.
                              </p>
                              <p><br></br></p>
                              <p><strong>2. What cookies are used?</strong></p>
                              <p><br></br></p>
                              <div id="ot-sdk-cookie-policy">
                                  <div id="ot-sdk-cookie-policy-v2" className="ot-sdk-cookie-policy ot-sdk-container">
                                      <h3 id="cookie-policy-title" className="ot-sdk-cookie-policy-title">Cookie List</h3>
                                      <div id="cookie-policy-description">A cookie is a small piece of data (text file) that a
                                          website – when visited by a user – asks your browser to store on your device in order to
                                          remember information about you, such as your language preference or login information.
                                          Those cookies are set by us and called first-party cookies. We also use third-party
                                          cookies – which are cookies from a domain different than the domain of the website you
                                          are visiting – for our advertising and marketing efforts. More specifically, we use
                                          cookies and other tracking technologies for the following purposes:</div>

                                      <section>
                                          <h4 className="ot-sdk-cookie-policy-group">Strictly Necessary Cookies</h4>
                                          <p className="ot-sdk-cookie-policy-group-desc">These cookies are necessary for the website
                                              to function and cannot be switched off in our systems. They are usually only set in
                                              response to actions made by you which amount to a request for services, such as
                                              setting your privacy preferences, logging in or filling in forms. You can set your
                                              browser to block or alert you about these cookies, but some parts of the site will
                                              not then work. These cookies do not store any personally identifiable information.
                                          </p>

                                          <table>
                                              <caption className="ot-scrn-rdr">Strictly Necessary Cookies</caption>
                                              <thead>
                                                  <tr>
                                                      <th scope="col" className="ot-table-header ot-host">Cookie Subgroup</th>

                                                      <th scope="col" className="ot-table-header ot-cookies">Cookies</th>
                                                      <th scope="col" className="ot-table-header ot-cookies-type">Cookies used</th>

                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td className="ot-host-td" data-label="Cookie Subgroup"><span
                                                              className="ot-mobile-border"></span>.publicisetnous.com</td>

                                                      <td className="ot-cookies-td" data-label="Cookies">
                                                          <span className="ot-mobile-border"></span>
                                                          <span className="ot-cookies-td-content">
                                                              <a href="https://cookiepedia.co.uk/cookies/OptanonAlertBoxClosed"
                                                                  rel="noopener" target="_blank"
                                                                  aria-label="OptanonAlertBoxClosed Opens in a new Tab">
                                                                  OptanonAlertBoxClosed
                                                              </a>,
                                                              <a href="https://cookiepedia.co.uk/cookies/OptanonConsent"
                                                                  rel="noopener" target="_blank"
                                                                  aria-label="OptanonConsent Opens in a new Tab">
                                                                  OptanonConsent
                                                              </a></span>
                                                      </td>
                                                      <td className="ot-cookies-type" data-label="Cookies used">
                                                          <span className="ot-mobile-border"></span>
                                                          <span className="ot-cookies-type-td-content">First Party</span>
                                                      </td>

                                                  </tr>
                                                  <tr>
                                                      <td className="ot-host-td" data-label="Cookie Subgroup"><span
                                                              className="ot-mobile-border"></span><a
                                                              href="https://cookiepedia.co.uk/host/matomo.publicisfrance.com"
                                                              rel="noopener" target="_blank"
                                                              aria-label="matomo.publicisfrance.com Opens in a new Tab">
                                                              matomo.publicisfrance.com
                                                          </a></td>

                                                      <td className="ot-cookies-td" data-label="Cookies">
                                                          <span className="ot-mobile-border"></span>
                                                          <span className="ot-cookies-td-content">SERVERID</span>
                                                      </td>
                                                      <td className="ot-cookies-type" data-label="Cookies used">
                                                          <span className="ot-mobile-border"></span>
                                                          <span className="ot-cookies-type-td-content">Third Party</span>
                                                      </td>

                                                  </tr>
                                              </tbody>
                                          </table>
                                      </section>
                                      <section>
                                          <h4 className="ot-sdk-cookie-policy-group">Anonymized audience measurement cookies</h4>
                                          <p className="ot-sdk-cookie-policy-group-desc">These cookies allow us to determine the
                                              number of visits and the sources of traffic, in order to measure and improve the
                                              performance of our website and cannot be disabled. They also help us identify the
                                              most / least visited pages and assess how visitors navigate the website. All
                                              information collected by these cookies is anonymous.</p>

                                          <table>
                                              <caption className="ot-scrn-rdr">Anonymized audience measurement cookies</caption>
                                              <thead>
                                                  <tr>
                                                      <th scope="col" className="ot-table-header ot-host">Cookie Subgroup</th>

                                                      <th scope="col" className="ot-table-header ot-cookies">Cookies</th>
                                                      <th scope="col" className="ot-table-header ot-cookies-type">Cookies used</th>

                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                      <td className="ot-host-td" data-label="Cookie Subgroup"><span
                                                              className="ot-mobile-border"></span>publicisetnous.com</td>

                                                      <td className="ot-cookies-td" data-label="Cookies">
                                                          <span className="ot-mobile-border"></span>
                                                          <span className="ot-cookies-td-content">
                                                              <a href="https://cookiepedia.co.uk/cookies/_pk_ses*" rel="noopener"
                                                                  target="_blank" aria-label="_pk_ses* Opens in a new Tab">
                                                                  _pk_ses*
                                                              </a>,
                                                              <a href="https://cookiepedia.co.uk/cookies/_pk_id*" rel="noopener"
                                                                  target="_blank" aria-label="_pk_id* Opens in a new Tab">
                                                                  _pk_id*
                                                              </a></span>
                                                      </td>
                                                      <td className="ot-cookies-type" data-label="Cookies used">
                                                          <span className="ot-mobile-border"></span>
                                                          <span className="ot-cookies-type-td-content">First Party</span>
                                                      </td>

                                                  </tr>
                                                  <tr>
                                                      <td className="ot-host-td" data-label="Cookie Subgroup"><span
                                                              className="ot-mobile-border"></span><a
                                                              href="https://cookiepedia.co.uk/host/matomo.publicisfrance.com"
                                                              rel="noopener" target="_blank"
                                                              aria-label="matomo.publicisfrance.com Opens in a new Tab">
                                                              matomo.publicisfrance.com
                                                          </a></td>

                                                      <td className="ot-cookies-td" data-label="Cookies">
                                                          <span className="ot-mobile-border"></span>
                                                          <span className="ot-cookies-td-content">_pk_id*, _pk_ses*</span>
                                                      </td>
                                                      <td className="ot-cookies-type" data-label="Cookies used">
                                                          <span className="ot-mobile-border"></span>
                                                          <span className="ot-cookies-type-td-content">Third Party</span>
                                                      </td>

                                                  </tr>
                                              </tbody>
                                          </table>
                                      </section>
                                  </div>
                              </div>
                              <p><br></br></p>
                              <p><strong>3. How do I manage cookies?</strong></p>
                              <p><br></br></p>
                              <p>You can choose to accept or decline some or all Cookies. On your first visit, by clicking the
                                  "Cookie settings" link on the Cookie banner, You will be able to set your preferences according
                                  to the type of Cookies. You can change your Cookie preferences at any time using the button
                                  available at the footer of our Site.</p>
                          </div>
                      </section>
                      </div>
                    <div className="otnotice-version"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

// export const query = graphql`
//   {
//     allContentfulHomeBlockLive {
//       nodes {
//         blockName
//         image1Text {
//           gatsbyImageData
//         }
//       }
//     }
//   }
// `

export default Privacy;